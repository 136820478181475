@use '@angular/material' as mat;
@use 'sass:list';
@import '../variables/variables';

%title {
    font-size: 40px;
    line-height: 48px;
    font-family: $font-merriweather;
    margin-bottom: 36px;

    &.nomarg {
        margin-bottom: 0;
    }
}

.box {
    padding: 15px 25px;
    border: 1px solid $gray-light;
    border-radius: 4px;
    cursor: pointer;

    &:hover,
    &.active {
        border-color: $gray-light;
        box-shadow: $box-shadow-strong;
    }

    .name {
        font-weight: 500;
        font-size: 19px;
    }

    button {
        width: 100%;
        margin-top: 25px;
        text-transform: uppercase;
    }

    .mat-card-avatar {
        height: 50px;
        width: 50px;
        border-radius: 50%;
    }

    .name-image {
        align-items: center;
        margin-bottom: -10px;
    }
}

%custom-card {
    box-shadow: none;
    border: 1px solid $gray-background;
    transition: box-shadow 0.3s, transform 0.2s;
    font-family: $font-work-poppins;

    .mat-card-avatar {
        width: 50px;
        height: 50px;
        overflow: hidden;
    }

    .mat-card-header {
        .mat-card-title {
            font-size: 18px;
            text-align: left;
            margin-top: 6px;
            color: $primary;
            font-weight: 600;
        }
    }

    &:hover {
        @include mat.elevation(2);

        box-shadow: $box-shadow-medium;
    }

    &.active {
        box-shadow: $box-shadow-medium;
    }

    .name {
        font-weight: 500;
    }

    button {
        width: 100%;
        margin-top: 25px;
        text-transform: uppercase;
    }
}

.tag {
    text-transform: uppercase;
    border-radius: $border-radius;
    color: white;
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
    padding: 6px 10px;
    margin-bottom: 5px;

    &.dots {
        padding: 6px;
    }
}

////////////////////////////////////////////////////////////////////////

.form-element-static-text {
    margin-bottom: -15px;
}

.form-element-static-heading + .form-element-static-text {
    margin-top: -40px;
}

.custom-tooltip {
    white-space: pre-line;
}

.action-buttons {
    .btn-save {
        mat-spinner {
            margin: 0 auto;
        }

        height: 36px;

        ::ng-deep .mat-spinner {
            circle {
                stroke: white !important;
            }
        }
    }

    .mat-flat-button.mat-accent[disabled] {
        background-color: mat.get-color-from-palette($elias-app-primary-green);
    }
}

///////////////////////////////////////////////////////////////////////////

.container {
    padding: 40px 0 0;
    width: 90%;
    margin-bottom: 35px;
    font-family: $font-work-poppins;

    .close-icon,
    .menu-button {
        position: absolute;
        top: 70px;
        right: 30px;
    }

    .menu-button-icon {
        position: absolute;
        right: -17px;
        top: 63px;
    }

    .action-buttons {
        box-shadow: 0 0 5px 0 rgb(51 71 86 / 9%), 0 0 14px 0 rgb(51 71 86 / 7%);
        height: 65px;
        background-color: white;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 45%;
        align-items: center;
        z-index: 2;

        .btn-save {
            width: 135px;
        }

        .changes {
            position: relative;
            font-weight: 400;
            line-height: 20px;
            font-size: 13px;
            color: mat.get-color-from-palette($elias-app-primary, 500);
        }
    }

    .action-buttons > * {
        left: 4%;
    }
}

////////////////////////////////////////////////////////

.property-editor-container {
    padding: 40px 0 50px;
    font-family: $font-work-poppins;
}

/////////////////////////////////////////////////////////////////

$modal-header-height: 106px;

.modal-container {
    font-family: $font-work-poppins;
    height: 520px;
    width: 589px;
    overflow-x: hidden;
    position: relative;

    h1 {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ff2654;
        height: $modal-header-height;
        color: white;
        font-family: $font-work-poppins;
        font-size: 30px;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 0;
    }

    p {
        font-size: 14px;
        display: inline-block;
        width: 85%;
        margin-top: 35px;
    }

    form {
        display: block;
        height: calc(100% - #{$modal-header-height});
        position: relative;
    }

    .modal-content {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 16px;
        font-family: $font-work-poppins;
        flex-direction: column;
    }

    .modal-action-buttons {
        position: absolute;
        bottom: 50px;
        display: flex;
        justify-content: space-between;
        width: 100%;

        &.add-section {
            margin-top: 30px;
        }

        .modal-cancel-button {
            margin-left: 45px;
            height: 47px;
            min-width: 135px;
        }

        .modal-save-button {
            height: 47px;
            min-width: 135px;
            margin-right: 50px;
        }
    }

    &.confirm-container {
        .modal-action-buttons {
            margin-top: 20px;
        }
    }

    &.delete-container,
    &.confirm-container {
        mat-form-field {
            width: 45%;

            &.mat-focused {
                &.mat-form-field-type-mat-input {
                    &.mat-form-field-appearance-outline .mat-form-field-outline {
                        color: $danger;
                    }

                    mat-label {
                        color: $danger;
                    }
                }
            }

            .mat-form-field-wrapper {
                margin: 2.5em 0 !important;
            }
        }

        &.notext {
            height: 330px !important;
        }
    }

    &.refresh-or-logout-container {
        position: relative;

        .modal-action-buttons {
            position: absolute;
            left: 40%;
        }
    }
}

///////////////////////////////////////////////////////////////

.system-create-container {
    font-family: $font-work-poppins;

    .close-icon {
        position: absolute;
        right: 0;
        top: 15px;
        cursor: pointer;
        z-index: 2;

        &:hover {
            color: $primary-green;
        }
    }

    p {
        width: 98%;
    }

    .step-container {
        overflow-y: auto;
        overflow-x: hidden;
        height: 358px;
        justify-content: center;
        align-items: flex-start;
        margin: 0 20px;
        display: flex;
    }

    .app-form {
        display: flex;
        justify-content: center;
    }
}

.system-container {
    font-family: $font-work-poppins;

    .mat-expansion-panel {
        border: 1px solid rgb(193 193 193 / 50%);
        background-color: rgb(216 216 216 / 5%);
        transition: transform 0.2s ease;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 5px;

        &:hover {
            @include mat.elevation(4);

            background-color: white;
            box-shadow: $box-shadow-medium;
        }

        .panel-description {
            display: flex;
            align-items: center;
        }

        &:focus {
            outline: none;
        }

        .list-delete-button,
        .list-enable-button {
            margin-right: 80px;
        }

        .list-save-button {
            margin-right: 15px;
        }

        .list-show-button {
            margin-right: 80px;
        }

        .form-elements {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .mat-expansion-panel:not([class*='mat-elevation-z']) {
        box-shadow: none;
    }

    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
        background: none;
    }

    .mat-expansion-panel-header-description {
        width: 35%;
        margin: auto;
    }

    .mat-expansion-panel-header-title {
        width: 65%;
    }

    .mat-action-row {
        border-top-color: white;
    }

    .colored-ball {
        padding: 2px 12px;
        border-radius: 100%;
        height: 25px;
        width: 7px;
    }

    mat-panel-title {
        align-items: center;

        p {
            color: $primary;
            font-family: $font-work-poppins;
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
            margin-left: 35px;
        }
    }
}

/////////////////////////////////////////////////////////////////

.default-image {
    .background-image {
        border-radius: 50%;
        height: 40px;
        width: 40px;
        position: absolute;
        opacity: 0.4;
    }

    .initials {
        position: absolute;
        font-size: 15px;
        height: 40px;
        width: 40px;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}

/////////////////////////////////////////////////////////////////

.search-field {
    margin-top: 6px;
    position: relative;

    input {
        outline: none;
    }

    input[type='search'] {
        appearance: textfield;
        box-sizing: content-box;
        font-size: 100%;
        background: url('/../../assets/images/search-icon.png') no-repeat 9px center;
        color: transparent;
        border: 1px solid transparent;
        transition: all 0.5s;
        height: 17px;
        width: 15px;
        padding: 9px 10px;
        cursor: pointer;
    }

    input::-webkit-search-decoration,
    input::-webkit-search-cancel-button {
        display: none;
    }

    .search-cancel {
        position: absolute;
        right: -5px;
        top: 8px;
        opacity: 0;
        cursor: pointer;
        pointer-events: none;

        &:hover {
            color: $primary-green;
        }
    }

    input::placeholder {
        color: transparent;
    }

    input[type='search']:focus,
    .search-input:not([value=''], :focus):valid {
        width: 180px;
        padding-left: 32px;
        color: $primary-dark-grey;
        cursor: auto;
        border: 1px solid $primary-green;
        border-radius: 5px;
    }

    input[type='search']:focus + .search-cancel,
    .search-input:not([value=''], :focus):valid + .search-cancel {
        transition: all 3s;
        opacity: 1;
        pointer-events: all;
    }

    input:invalid {
        box-shadow: none;
    }
}

.mat-autocomplete-panel {
    max-height: 300px !important;
    min-width: 250px !important;
}

/////////////////////////////////////////////////////////////////

$blue: #ff2654;
$red: #fcab24;
$yellow: #00dbc9;
$green: #8526ff;
$colors-list: $blue $red $yellow $green;

.google-loader {
    span {
        display: inline-block;
        margin-top: 10px;
        height: 15px;
        width: 15px;
        border-radius: 50%;

        &:not(:first-child) {
            margin-left: 10px;
        }
    }
    @each $current-color in $colors-list {
        $i: list.index($colors-list, $current-color);
        $t: $i * -0.25;
        span:nth-child(#{$i}) {
            background: $current-color;
            animation: move 0.6s ease-in-out (#{$t}s) infinite alternate;
        }
    }
}

@keyframes move {
    from {
        transform: translateY(-10px);
    }

    to {
        transform: translateY(10px);
    }
}

.tox .tox-pop {
    min-width: 160px;
    max-width: none !important;
}
