@font-face {
    font-family: 'elias-icons';
    src: url('../font/elias-icons.eot?19582941');
    src: url('../font/elias-icons.eot?19582941#iefix') format('embedded-opentype'),
    url('../font/elias-icons.woff2?19582941') format('woff2'),
    url('../font/elias-icons.woff?19582941') format('woff'),
    url('../font/elias-icons.ttf?19582941') format('truetype'),
    url('../font/elias-icons.svg?19582941#elias-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'elias-icons';
    src: url('../font/elias-icons.svg?84094648#elias-icons') format('svg');
  }
}
*/

[class^="ei"]:before, [class*=" ei"]:before {
    font-family: "elias-icons";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    /*margin-right: .2em;*/
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /*margin-left: .2em;*/

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */

    /*overriding default css*/
    font-size: 21px;
    margin-right: .4em;
}

.ei-150{
  font-size: 1.5em;
}

.ei-assignment:before { content: '\e800'; } /* '' */
.ei-bell:before { content: '\e801'; } /* '' */
.ei-block:before { content: '\e802'; } /* '' */
.ei-calendar:before { content: '\e803'; } /* '' */
.ei-cancel-full:before { content: '\e804'; } /* '' */
.ei-cancel:before { content: '\e805'; } /* '' */
.ei-card:before { content: '\e806'; } /* '' */
.ei-check-full:before { content: '\e807'; } /* '' */
.ei-check:before { content: '\e808'; } /* '' */
.ei-close:before { content: '\e809'; } /* '' */
.ei-comment:before { content: '\e80a'; } /* '' */
.ei-comment-full:before { content: '\e92c'; } /* '' */
.ei-copy:before { content: '\e80b'; } /* '' */
.ei-create:before { content: '\e80c'; } /* '' */
.ei-crop:before { content: '\e80d'; } /* '' */
.ei-done:before { content: '\e80e'; } /* '' */
.ei-download:before { content: '\e80f'; } /* '' */
.ei-edit:before { content: '\e810'; } /* '' */
.ei-error-full:before { content: '\e811'; } /* '' */
.ei-error:before { content: '\e812'; } /* '' */
.ei-expand:before { content: '\e813'; } /* '' */
.ei-favorite-full:before { content: '\e814'; } /* '' */
.ei-favorite:before { content: '\e815'; } /* '' */
.ei-folder-full:before { content: '\e816'; } /* '' */
.ei-folder:before { content: '\e817'; } /* '' */
.ei-help-full:before { content: '\e818'; } /* '' */
.ei-help:before { content: '\e819'; } /* '' */
.ei-info-full:before { content: '\e81a'; } /* '' */
.ei-info:before { content: '\e81b'; } /* '' */
.ei-layers:before { content: '\e81c'; } /* '' */
.ei-link:before { content: '\e81d'; } /* '' */
.ei-list:before { content: '\e81e'; } /* '' */
.ei-lock:before { content: '\e81f'; } /* '' */
.ei-login:before { content: '\e820'; } /* '' */
.ei-logout:before { content: '\e821'; } /* '' */
.ei-mail:before { content: '\e822'; } /* '' */
.ei-minimize:before { content: '\e823'; } /* '' */
.ei-minus-full:before { content: '\e824'; } /* '' */
.ei-minus:before { content: '\e825'; } /* '' */
.ei-phone:before { content: '\e826'; } /* '' */
.ei-photo:before { content: '\e827'; } /* '' */
.ei-plus-full:before { content: '\e828'; } /* '' */
.ei-plus:before { content: '\e829'; } /* '' */
.ei-public:before { content: '\e82a'; } /* '' */
.ei-refresh:before { content: '\e82b'; } /* '' */
.ei-search:before { content: '\e82c'; } /* '' */
.ei-settings:before { content: '\e82d'; } /* '' */
.ei-star-full:before { content: '\e82e'; } /* '' */
.ei-staroutline:before { content: '\e82f'; } /* '' */
.ei-trash:before { content: '\e830'; } /* '' */
.ei-unlock:before { content: '\e831'; } /* '' */
.ei-user-add:before { content: '\e832'; } /* '' */
.ei-user-delete:before { content: '\e833'; } /* '' */
.ei-user:before { content: '\e834'; } /* '' */
.ei-users:before { content: '\e835'; } /* '' */
.ei-visibility-off:before { content: '\e836'; } /* '' */
.ei-visibility:before { content: '\e837'; } /* '' */
.ei-doc:before { content: '\e838'; } /* '' */
.ei-add:before { content: '\e839'; } /* '' */
.ei-alert:before { content: '\e83a'; } /* '' */
.ei-arrows-down-thin:before { content: '\e83b'; } /* '' */
.ei-facebook:before { content: '\e83c'; } /* '' */
.ei-linkedin:before { content: '\e83d'; } /* '' */
.ei-medium:before { content: '\e83e'; } /* '' */
.ei-msword:before { content: '\e83f'; } /* '' */
.ei-pdf-full:before { content: '\e840'; } /* '' */
.ei-typo3:before { content: '\e841'; } /* '' */
.ei-tools-column-break:before { content: '\e842'; } /* '' */
.ei-tools-columns-end:before { content: '\e843'; } /* '' */
.ei-wordpress:before { content: '\e844'; } /* '' */
.ei-file-csv:before { content: '\e845'; } /* '' */
.ei-file-doc:before { content: '\e846'; } /* '' */
.ei-file-xls:before { content: '\e847'; } /* '' */
.ei-arrows-down:before { content: '\e848'; } /* '' */
.ei-arrows-left-thin:before { content: '\e849'; } /* '' */
.ei-arrows-left:before { content: '\e84a'; } /* '' */
.ei-arrows-right-thin:before { content: '\e84b'; } /* '' */
.ei-arrows-right:before { content: '\e84c'; } /* '' */
.ei-arrows-up-thin:before { content: '\e84d'; } /* '' */
.ei-arrows-up:before { content: '\e84e'; } /* '' */
.ei-file-pdf:before { content: '\e84f'; } /* '' */
.ei-tools-column-break-left:before { content: '\e850'; } /* '' */
.ei-tools-video:before { content: '\e851'; } /* '' */
.ei-tools-google-maps:before { content: '\e852'; } /* '' */
.ei-format-bold:before { content: '\e853'; } /* '' */
.ei-format-color-text:before { content: '\e854'; } /* '' */
.ei-format-italic:before { content: '\e855'; } /* '' */
.ei-format-list-bulleted:before { content: '\e856'; } /* '' */
.ei-format-list-numbered:before { content: '\e857'; } /* '' */
.ei-format-underlined:before { content: '\e858'; } /* '' */
.ei-remove:before { content: '\e859'; } /* '' */
.ei-space-bar:before { content: '\e85a'; } /* '' */
.ei-spellcheck:before { content: '\e85b'; } /* '' */
.ei-text-fields:before { content: '\e85c'; } /* '' */
.ei-vertical-align:before { content: '\e85d'; } /* '' */
.ei-vertical-align-top:before { content: '\e85e'; } /* '' */
.ei-arrow-down:before { content: '\e85f'; } /* '' */
.ei-arrow-left:before { content: '\e860'; } /* '' */
.ei-arrow-right:before { content: '\e861'; } /* '' */
.ei-arrow-upward:before { content: '\e862'; } /* '' */
.ei-dashboard:before { content: '\e863'; } /* '' */
.ei-drag:before { content: '\e864'; } /* '' */
.ei-fullscreen-exit:before { content: '\e865'; } /* '' */
.ei-box:before { content: '\e866'; } /* '' */
.ei-boxchecked:before { content: '\e867'; } /* '' */
.ei-boxinter:before { content: '\e868'; } /* '' */
.ei-fullscreen:before { content: '\e869'; } /* '' */
.ei-more-horiz:before { content: '\e86a'; } /* '' */
.ei-more-vert:before { content: '\e86b'; } /* '' */
.ei-tools-columns-start:before { content: '\e86c'; } /* '' */
.ei-tools-graphs-bar:before { content: '\e86d'; } /* '' */
.ei-tools-graphs-line:before { content: '\e86e'; } /* '' */
.ei-tools-graphs-stacked-bar:before { content: '\e86f'; } /* '' */
.ei-tools-image-gallery:before { content: '\e870'; } /* '' */
.ei-tools-image:before { content: '\e871'; } /* '' */
.ei-tools-lead:before { content: '\e872'; } /* '' */
.ei-tools-page-break:before { content: '\e873'; } /* '' */
.ei-tools-table:before { content: '\e874'; } /* '' */
.ei-tools-text:before { content: '\e875'; } /* '' */
.ei-tools-title:before { content: '\e876'; } /* '' */
.ei-tools-toc:before { content: '\e877'; } /* '' */
.ei-radio:before { content: '\e878'; } /* '' */
.ei-radioselect:before { content: '\e879'; } /* '' */
.ei-subdirectory-arrow-left:before { content: '\e87a'; } /* '' */
.ei-subdirectory-arrow-right:before { content: '\e87b'; } /* '' */
.ei-unfold-less:before { content: '\e87c'; } /* '' */
.ei-unfold-more:before { content: '\e87d'; } /* '' */
.ei-video:before { content: '\e87e'; } /* '' */
.ei-divider:before { content: '\e880'; } /* '' */
.ei-tools-footnote:before { content: '\e881'; } /* '' */
.ei-tools-graphs-pie:before { content: '\e883'; } /* '' */
.ei-tools-disclaimer:before { content: '\e884'; } /* '' */
.ei-tools-key-figure:before { content: '\e885'; } /* '' */
.ei-tools-graphs-paired-bar:before { content: '\e887'; } /* '' */
.ei-tools-graphs-paired-bar-line:before { content: '\e888'; } /* '' */
.ei-tools-portfolio-gallery:before { content: '\e88b'; } /* '' */
.ei-drag-drop:before { content: '\e88d'; } /* '' */
.ei-publication:before { content: '\e891'; } /* '' */
.ei-section:before { content: '\e892'; } /* '' */
.ei-answer:before { content: '\e893'; } /* '' */
.ei-comment-reopened:before { content: '\e89d'; } /* '' */
.ei-comment-replied:before { content: '\e89e'; } /* '' */
.ei-comment-resolved:before { content: '\e89f'; } /* '' */
.ei-comment-mention:before { content: '\e8a0'; } /* '' */
.ei-tools-graphs-bubble:before { content: '\e8db'; } /* '' */
.ei-tools-graphs-map:before { content: '\e8dc'; } /* '' */
.ei-book:before { content: '\e91c'; } /* '' */
.ei-access:before { content: '\e920'; } /* '' */
.ei-mention:before { content: '\e921'; } /* '' */
.ei-processing:before { content: '\e922'; } /* '' */
.ei-processing-full:before { content: '\e923'; } /* '' */
.ei-goto:before { content: '\e929'; } /* '' */
.ei-doc-text:before { content: '\f0f6'; } /* '' */
.ei-file-image:before { content: '\f1c5'; } /* '' */
.ei-file-archive:before { content: '\f1c6'; } /* '' */
.ei-file-audio:before { content: '\f1c7'; } /* '' */
