@import '../variables/variables';

.document {
    font-family: $font-work-poppins;

    img {
        max-width: 100%;
    }

    .mce-edit-focus {
        outline: 0 !important;
        border: none;
    }

    .table {
        width: 100%;
        table-layout: fixed;

        td {
            padding: 0.75rem;
        }
    }
}

.preview-container {
    width: 1170px;
    border: 10px solid $primary-green;
    overflow: hidden;
}
